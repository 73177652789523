var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$lang("users.aggregators.title"))+" ")]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","small":"","fab":""},on:{"click":_vm.showCreateDialog}},[_c('v-icon',[_vm._v("add")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"label":_vm.$lang('users.aggregatorEntity'),"items":_vm.entities,"outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.entity),callback:function ($$v) {_vm.entity=$$v},expression:"entity"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$lang('users.search'),"outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.aggregators,"items-per-page":25,"footer-props":{
          itemsPerPageOptions: [25, 50, 100],
        },"loading":_vm.isAggregatorsLoading},scopedSlots:_vm._u([{key:"item.entities",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getEntitiesNames(item))+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.showEditDialog(item)}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$lang("users.aggregators.editHint")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.showDeleteDialog(item)}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$lang("users.aggregators.deleteHint")))])])]}}],null,true)})],1)],1),_c('aggregator-form-dialog',{attrs:{"aggregator":_vm.editedAggregator,"entity":_vm.entity},on:{"create":_vm.createAggregator,"update":_vm.updateAggregator},model:{value:(_vm.isFormDialogVisible),callback:function ($$v) {_vm.isFormDialogVisible=$$v},expression:"isFormDialogVisible"}}),_c('confirmation-dialog',{attrs:{"loading":_vm.isAggregatorDeleting,"title":_vm.$lang(
        "users.aggregators.deleteAggregator",
        _vm.deletedAggregator ? _vm.deletedAggregator.name : ''
      ),"btn-text":_vm.$lang('btn.delete')},on:{"confirm":_vm.deleteAggregator},model:{value:(_vm.isDeleteDialogVisible),callback:function ($$v) {_vm.isDeleteDialogVisible=$$v},expression:"isDeleteDialogVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }