
























































































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import {
  AppSectionAggregator,
  PermissionAggregatorModel,
  PermissionEntity,
  PermissionEntityNames,
} from "@/iam/models/PermissionModel";
import { ReportType } from "@/reports/models";
import ReportUtil from "@/reports/utils/ReportUtil";
import { GENERAL_SECTIONS } from "@/shared/models";
import { VueForm } from "@/shared/types/ExtendedVueType";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component
export default class AggregatorFormDialog extends Vue {
  @Prop() value!: boolean;
  @Prop() aggregator?: PermissionAggregatorModel;
  @Prop() entity?: PermissionEntity;
  @Ref("form") readonly form!: VueForm;

  readonly typeItems: Array<Record<string, string>> = [
    PermissionEntity.APPLICATION,
    PermissionEntity.APP_SECTION,
    PermissionEntity.AD_NETWORK,
  ].map((value) => ({
    text: this.$lang(`users.permissionEntity.${value.toLowerCase()}`),
    value,
  }));
  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly requiredArray = [
    ValidUtil.requiredArray(this.$lang("validation.required")),
  ];

  valid = true;
  type: PermissionEntity | null = null;
  name: string | null = null;
  aggregatorType = AppSectionAggregator.GENERAL_APP_SECTIONS;
  entities: Array<string> = [];
  description = "";

  // get aggregatorType(): AppSectionAggregator | null {
  //   return this.aggregator?.aggregatorType ?? null;
  // }

  get visible(): boolean {
    return this.value;
  }

  set visible(newValue: boolean) {
    this.$emit("input", newValue);
  }

  get title(): string {
    return this.aggregator
      ? this.$lang("users.aggregators.editAggregator", this.aggregator.name)
      : this.$lang("users.aggregators.createNewAggregator");
  }

  get entitiesItems(): Array<Record<string, string>> {
    return (
      this.$store.state.usersStore.permissionEntityNames
        .find(
          ({ ngacEntityName }: PermissionEntityNames) =>
            ngacEntityName === this.type
        )
        ?.ngacNames.flatMap((value: string) => {
          if (
            this.isAppSectionType &&
            ((this.aggregatorType ===
              AppSectionAggregator.GENERAL_APP_SECTIONS &&
              !GENERAL_SECTIONS.includes(value)) ||
              (this.aggregatorType !==
                AppSectionAggregator.GENERAL_APP_SECTIONS &&
                GENERAL_SECTIONS.includes(value)))
          ) {
            return [];
          }

          return [
            {
              text: this.getEntityName(value),
              value,
            },
          ];
        }) ?? []
    ).sort((itemA: Record<string, string>, itemB: Record<string, string>) => {
      const textA = itemA.text.toLowerCase();
      const textB = itemB.text.toLowerCase();

      if (textA === textB) {
        return 0;
      }

      return textA < textB ? -1 : 1;
    });
  }

  get isAggregatorSaving(): boolean {
    return this.$store.state.usersStore.isAggregatorSaving;
  }

  get aggregatorTypes(): Array<{ text: string; value: string }> {
    return Object.values(AppSectionAggregator).map((value) => ({
      text: this.$lang(
        `users.aggregators.aggregatorType.${value.toLowerCase()}`
      ),
      value,
    }));
  }

  get isAppSectionType(): boolean {
    return this.type === PermissionEntity.APP_SECTION;
  }

  @Watch("value", { immediate: true })
  async watchValue() {
    if (!this.value) {
      return;
    }

    this.type = this.aggregator?.type ?? this.entity ?? null;
    this.name = this.aggregator?.name ?? null;
    this.entities = this.aggregator?.entities ?? [];
    this.description = this.aggregator?.description ?? "";
    await this.$nextTick();
    this.form.resetValidation();
  }

  @Watch("aggregator", { immediate: true })
  watchAggregator() {
    if (
      this.aggregator?.type === PermissionEntity.APP_SECTION &&
      this.aggregator.aggregatorType
    ) {
      this.aggregatorType = this.aggregator.aggregatorType;
    }
  }

  save() {
    if (!this.form.validate()) {
      return;
    }

    this.$emit(
      this.aggregator ? "update" : "create",
      new PermissionAggregatorModel(
        this.name as string,
        this.isAppSectionType ? this.aggregatorType : null,
        this.type as PermissionEntity,
        this.entities,
        this.description
      )
    );
  }

  resetEntities() {
    this.entities = [];
  }

  getEntityName(value: string): string {
    if (this.type === PermissionEntity.APPLICATION) {
      return this.$store.getters.applicationsById.get(value)?.name || value;
    }

    if (this.type === PermissionEntity.APP_SECTION) {
      return value in ReportType
        ? ReportUtil.getReportNameByReportType(value as ReportType)
        : this.$lang(`users.appSection.${value.toLowerCase()}`);
    }

    return value;
  }
}
