







































































































import { Component, Vue, Watch } from "vue-property-decorator";

import AggregatorFormDialog from "@/iam/components/AggregatorFormDialog.vue";
import ConfirmationDialog from "@/shared/components/ConfirmationDialog.vue";
import {
  PermissionAggregatorModel,
  PermissionEntity,
} from "@/iam/models/PermissionModel";
import { ReportType } from "@/reports/models";
import ReportUtil from "@/reports/utils/ReportUtil";

@Component({ components: { AggregatorFormDialog, ConfirmationDialog } })
export default class AggregatorsView extends Vue {
  readonly entities: Array<Record<string, string>> = [
    PermissionEntity.APPLICATION,
    PermissionEntity.APP_SECTION,
    PermissionEntity.AD_NETWORK,
  ].map((value) => ({
    text: this.$lang(`users.permissionEntity.${value.toLowerCase()}`),
    value,
  }));
  readonly headers = [
    {
      text: this.$lang("users.aggregators.name"),
      value: "name",
      width: "20%",
    },
    {
      text: this.$lang("users.aggregators.description"),
      value: "description",
      width: "25%",
      sortable: false,
    },
    {
      text: this.$lang("users.aggregators.entities"),
      value: "entities",
      sortable: false,
    },
    {
      text: this.$lang("users.aggregators.actions"),
      value: "actions",
      align: "end",
      width: "10%",
      sortable: false,
    },
  ];

  entity = this.entities[0].value;
  search = "";
  editedAggregator: PermissionAggregatorModel | null = null;
  isFormDialogVisible = false;
  deletedAggregator: PermissionAggregatorModel | null = null;
  isDeleteDialogVisible = false;

  get aggregators(): Array<PermissionAggregatorModel> {
    return this.$store.state.usersStore.aggregators.filter(
      ({ name }: PermissionAggregatorModel) =>
        name.toLowerCase().includes(this.search.toLowerCase())
    );
  }

  get isAggregatorsLoading(): boolean {
    return this.$store.state.usersStore.isAggregatorsLoading;
  }

  get isAggregatorDeleting(): boolean {
    return this.$store.state.usersStore.isAggregatorDeleting;
  }

  @Watch("entity", { immediate: true })
  fetchAggregators() {
    this.$store.dispatch("fetchAggregators", this.entity);
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("users.aggregators.title")
    );
  }

  showCreateDialog() {
    this.editedAggregator = null;
    this.isFormDialogVisible = true;
  }

  async createAggregator(aggregator: PermissionAggregatorModel) {
    await this.$store.dispatch("createAggregator", aggregator);
    this.isFormDialogVisible = false;

    if (aggregator.type !== this.entity) {
      this.entity = aggregator.type;
    }
  }

  showEditDialog(aggregator: PermissionAggregatorModel) {
    this.editedAggregator = PermissionAggregatorModel.of(aggregator);
    this.isFormDialogVisible = true;
  }

  async updateAggregator(aggregator: PermissionAggregatorModel) {
    await this.$store.dispatch("updateAggregator", aggregator);
    this.isFormDialogVisible = false;
  }

  showDeleteDialog(aggregator: PermissionAggregatorModel) {
    this.deletedAggregator = PermissionAggregatorModel.of(aggregator);
    this.isDeleteDialogVisible = true;
  }

  async deleteAggregator() {
    await this.$store.dispatch("deleteAggregator", this.deletedAggregator);
    this.isDeleteDialogVisible = false;
  }

  getEntitiesNames({ type, entities }: PermissionAggregatorModel): string {
    return entities
      .map((value) => {
        if (type === PermissionEntity.APPLICATION) {
          return this.$store.getters.applicationsById.get(value)?.name || value;
        }

        if (type === PermissionEntity.APP_SECTION) {
          return value in ReportType
            ? ReportUtil.getReportNameByReportType(value as ReportType)
            : this.$lang(`users.appSection.${value.toLowerCase()}`);
        }

        return value;
      })
      .join(", ");
  }
}
